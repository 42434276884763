<template>
  <div>
    <!-- 融合报表 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams" >
        <el-form-item>
          <el-date-picker style="width: 230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.payType" placeholder="请选择支付方式" style="width:150px" @change="refresh">
            <el-option label="微信支付" :value="1"></el-option>
            <el-option label="支付宝支付" :value="2"></el-option>
            <el-option label="余额支付" :value="3"></el-option>
            <el-option label="线下支付" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-if="initParam.operator==''" v-model="queryParams.operator" placeholder="请选择运营商" clearable filterable @change="refresh">
            <el-option label="中国移动" value="中国移动"></el-option>
            <el-option label="中国联通" value="中国联通"></el-option>
            <el-option label="中国电信" value="中国电信"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入套餐名称" v-model="queryParams.mealName"></el-input>
        </el-form-item> 
        
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="refresh" >搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-link :href="BaseUrl + `net//admin/report/noLogin/export?mergeFlag=1&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&mealName=${queryParams.mealName}&shopId=${initParam.shopId}&operator=${queryParams.operator}&schoolId=${initParam.schoolId}&userId=${userId}`" class="button_download">
            <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
          </el-link>
        </el-form-item>
        <el-form-item>
          <el-link :href="BaseUrl + `net//admin/report/noLogin/exportDetail?mergeFlag=1&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&mealName=${queryParams.mealName}&shopId=${initParam.shopId}&operator=${queryParams.operator}&schoolId=${initParam.schoolId}&userId=${userId}`" class="button_download">
            <el-button type="warning" size="small" icon="el-icon-position">导出明细</el-button>
          </el-link>
        </el-form-item>
      </el-form>
      <!-- 添加 -->
      <div class="content-wrap">
        <!--列表-->
        <paged-table
          :data="broadBandList"
          :total="broadBandTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh">
          >
          <el-table-column
            prop="schoolName"
            label="学校"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="operator"
            label="运营商"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="mealName"
            label="套餐"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="addNum"
            label="新增用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="startOnNum"
            label="初期全部用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="startNormalNum"
            label="初期正常用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="endOnNum"
            label="期末全部用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="endNormalNum"
            label="期末正常用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions,mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import BaseUpload from "@/components/common/BaseUpload";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import datePicker from "@/mixins/datePicker";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import Tinymce from "@/tinymce";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'broadBandList',
  components: {
    PagedTable,
    BaseUpload,
    AreaTree,
    Tinymce
  },
  mixins: [pageMixin,provinces,download, datePicker],
  data() {
    return {
      userId: sessionStorage.getItem('userId'),
      url: '',
      BaseUrl,
      isChecked:false,
      queryParams: {
        mergeFlag: 1,
        pageNum: 1,
        pageSize: 10,
        payType: null,
        mealName: '',
        startDate: '',
        endDate: ''       
      },
      initParam:{
        shopId: sessionStorage.getItem('shopId'),
        operator: sessionStorage.getItem('operator') == 'null' ? '': sessionStorage.getItem('operator'),
        schoolId: sessionStorage.getItem('schoolId') == 'null' ? '': sessionStorage.getItem('schoolId')
      },
      pageApi:'broadBandListGetList',
    };
  },
  computed: {
    ...mapState("broadBandList", ["broadBandList", "broadBandTotal"]),
  },
  methods: {
    ...mapActions("broadBandList",[ "broadBandListGetList", ]),
    // 图片上传
     handleBeforeUpload(file, callback){
		  const isPicture = file.name.includes('jpg') || file.name.includes('png') || file.name.includes('gif');
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isPicture) {
        this.$message.error('上传图片只能是 JPG/PNG/gif 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      callback(isPicture && isLt2M)
    },
    handleQuery(data){
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
        this.initParam.schoolId = data.id;
      }
      this.refresh()
    },
    timeRangeChange(val, index){
        this.gameTime[index].startDate = val ? val[0] : ''
        this.gameTime[index].endDate = val ? val[1] : ''
    },
  },
  created(){
  },
  mounted(){
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    this.refresh()
  }
};
</script>
